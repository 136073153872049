<template>
  <a-modal
      :title="isEditing ?'Dosya Düzenle': 'Dosya Ekle'"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Dosya Seçin
      </a-col>

      <a-col>
        <a-select
            ref="course_select"
            show-search
            v-model:value="selectedLevelFile"
            @change="onSelectChanged"
            style="width: 600px"
            :options="fileOptions"
            filterOption="filterOption"
        >
        </a-select>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        Dosya İsmi
      </a-col>

      <a-col>
        <a-input v-model:value="name" style="width: 600px"></a-input>
      </a-col>

    </a-row>
    <a-row>
      <a-col :flex="2">
        Kapak
      </a-col>
      <a-upload

          v-model:file-list="fileList"
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="`${config.BACKEND_URL}/files/upload-cover`"
          :before-upload="beforeUpload"
          @change="handleChange"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="img-container"/>
        <div v-else>
          <LoadingOutlined v-if="loading"></LoadingOutlined>
          <PlusOutlined v-else></PlusOutlined>
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>
    </a-row>
    <a-alert
        message="Lütfen aşağıdaki kısımları elinizden geldiğinde değiştirmeyin!"
        type="warning"
    />

    <a-row type="flex">

      <a-col :flex="2">
        Uzantı
      </a-col>

      <a-col>
        <a-input v-model:value="extension" style="width: 600px"></a-input>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        Yol
      </a-col>

      <a-col>
        <a-input v-model:value="path" style="width: 600px"></a-input>
      </a-col>

    </a-row>
    <a-row type="flex">

      <a-col :flex="2">
        size
      </a-col>

      <a-col>
        <a-input v-model:value="size" style="width: 500px"></a-input>
        <a-space style="margin-left: 10px;width: 90px">
          <a-col stlye="align-items:center">
            {{ formatBytes(size) }}
          </a-col>

        </a-space>
      </a-col>


    </a-row>


    <template #footer>
      <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOk">
        {{ isEditing ? 'Düzenle' : 'Kaydet' }}
      </a-button>
      <a-button key="iptal" @click="visible = false">İptal</a-button>
    </template>

  </a-modal>
</template>

<script>
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue'

import {ref} from 'vue'
import axios from 'axios'
import {formatBytes} from '../../../libs/helpers'
import {message} from 'ant-design-vue'
import config from '../../../../config'
import {useRoute} from 'vue-router'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'AddOrEditLevelFile',
  methods: {formatBytes},
  emits: ['finished'],
  components: {
    LoadingOutlined,
    PlusOutlined
  },
  setup(props, {emit}) {


    const router = useRoute()
    const id = ref(null)
    const name = ref('')
    const extension = ref('')
    const cover = ref(null)
    const path = ref('')
    const size = ref(0)
    const levelId = router.params.levelId


    // fileupload variables
    const fileList = ref([])
    const loading = ref(false)
    const imageUrl = ref('')
    const handleChange = info => {
      if (info.file.status === 'Yükleniyor') {
        loading.value = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, base64Url => {
          imageUrl.value = base64Url
          loading.value = false
        })
        // console.log(info.file.response.url)
        cover.value = info.file.response.url
      }
      if (info.file.status === 'error') {
        loading.value = false
        message.error('Yüklerken bir hata oluştu')
      }
    }
    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if ( ! isJpgOrPng) {
        message.error('JPG yada PNG dışında birşey yükleyemezsiniz!')
      }
      const isLt2M = file.size / 1024 / 500
      if ( ! isLt2M) {
        message.error('Resim 500Kb den fazla olmalıdır')
      }
      return isJpgOrPng && isLt2M
    }
    // --


    const fileOptions = ref([])
    const selectedLevelFile = ref(null)
    // name: data.file_name,
    //     extension: data.file_ext,
    //     cover: data.file_cover,
    //     path: data.file_path,
    //     level_id: data.file_level_id,

    const isEditing = ref(false)

    const visible = ref(false)
    const confirmLoading = ref(false)


    const showModal = async (_selectedFile) => {

      await axios.post('/google/files')
          .then((response) => {

            fileOptions.value = response.data.files.filter(item => item.extension !== 'folder').map(item => ({
              label: `${item.name}`,
              value: item.name,
              data: item
            }))
            selectedLevelFile.value = fileOptions.value[0].value

            name.value = fileOptions.value[0].data.name.split('/').at(-1)
            extension.value = fileOptions.value[0].data.extension
            path.value = fileOptions.value[0].data.name
            size.value = fileOptions.value[0].data.size
          })


      isEditing.value = false
      id.value = null

      // if (_selectedFile) {
      //   isEditing.value = true
      //   id.value = _selectedFile.id
      //   selectedLevelFile.value = _selectedFile.id
      // }

      visible.value = true

    }

    const onSelectChanged = (value, option) => {
      console.log('option', option)
      name.value = option.data.name.split('/').at(-1)
      extension.value = option.data.extension
      path.value = option.data.name
      size.value = option.data.size
    }

    const createLevel = async () => {
      return axios.post('/files', {
        file_name: name.value,
        file_ext: extension.value,
        file_cover: cover.value,
        file_path: path.value,
        file_size:Number(size.value),
        file_level_id: Number(levelId)
      })
    }

    // const updateLevel = async () => {
    //   return axios.post('/levels/update', {
    //     level_id: id.value,
    //     course_id:selectedCourse.value,
    //     name: levelTitle.value,
    //   })
    // }

    const filterOption = (input, option) => {
      return option.value.toLowerCase().includes(input.toLowerCase()) >= 0
    }
    const handleOk = () => {

      confirmLoading.value = true

      let funcToBeCalled = createLevel

      if (isEditing.value) {
        funcToBeCalled = updateLevel

      }

      funcToBeCalled()
          .then(() => {

            emit('finished')

          })
          .finally(() => {
            visible.value = false
            confirmLoading.value = false
          })


    }


    return {
      onSelectChanged,
      selectedLevelFile,
      filterOption,
      fileOptions,
      name,
      extension,
      cover,
      path,
      size,
      levelId,
      isEditing,
      visible,
      confirmLoading,
      showModal,
      handleOk,
      //image
      fileList,
      loading,
      imageUrl,
      handleChange,
      beforeUpload,
      config,
    }

  }


}
</script>
<style>
.img-container {
  width: 128px;
  height: 128px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
